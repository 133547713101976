
.popup-content1 .right-background input, 
.popup-content1  .right-background textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}
.popup-content1 button#sub {
  width: 100%;
  margin: 0;
}
.openPopup1 {
  position: fixed;
  right: -39px;
  color: #000 !important;
  background-color: #ffc107;
  border-color: #ffc107;
  top: 50%;
  z-index: 10;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.Section-input {
  width: 100%;
  height: 37px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 8px 0;
  font-size: 16px;
}
.Section-texterea {
  width: 100%;
  height: 100px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 8px 0;
  font-size: 16px;
  resize: none;
}
.Section-texterea:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.Section-input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.Submit {
  background: #166ce7;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
}
.popup-content1 {
  background-color: #f8f9fa;
  width: 60%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  position: fixed;
  top: 60%;
  z-index: 9999;
}
.close-btn1 {
  color: #000;
  float: right;
  font-size: 27px;
  cursor: pointer;
  position: absolute;
  right: 13px;
  top: 0;
}
.left-background {
  background: #166ce7;
  width: 50%;
  height: 430px;
  padding: 30px;
}
.left-background h3 {
  color: #f8f9fa !important;
  font-size: 1.75rem;
}
.left-background p {
  color: #f8f9fa !important;
  line-height: 25px !important;
  font-size: 16px;
  margin: 14px 1px;
}
.right-background {
  width: 50%;
  padding: 0 10px;
}
.dis-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tanwork img {
  width: 80%;
  margin: auto;
  display: flex;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
@media only screen and (max-width: 600px) {
  .openPopup1 {
    display: none;
  }
}


@media only screen and (max-width: 767px) {
  .popup-content1.dis-flex {
    flex-wrap: wrap;
}
.left-background {
  width: 100%;
  height: 160px;
  padding: 20px;
}
.left-background h3 {
  margin: 0;
  margin-top: 10px;
}
.right-background{
  width: 100%;
  padding: 20px;
}
.tanwork img{
  display: none;
}
.popup-content1{
  top: 50%;
}
}
@media only screen and (max-width:639px) {
  .popup-content1.dis-flex {
      width: 90%;
  }
}



