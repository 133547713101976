.card12{
    padding: 30px 1%;
    text-align: center;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    position: relative; 
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .1);
    box-shadow: 0 0 2px rgba(0, 0, 0, .1);
    display: grid;
    width: 25%;
}

.card12 .icon-box{
    display: flex;
    justify-content: center;
}
.card12 i{
    color: #ff8100 !important;
    float: left;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    vertical-align: middle; 
    border: 4px solid #f3f3f3;
    border-radius: 100%;
}
.card12 h3{
    font-weight: 400;
    margin: 10px;
    font-size: 20px;

}
.card12:hover {
    background-color: #f0f0f0;
}
.card12:hover .icon-box i{
    background-color: #f9f9f9;
    border: 4px solid #fff;
}
.card12 p{
    text-align: center;
    font-size: 13px;
    margin-bottom: 10px;
}
.container-flaex {
    display: flex;
    width: 85%;
    margin: 0 auto;
    gap: 28px;
  }
 .redss{
    color: #999;
    cursor: pointer;
    font-size: 14px;
  }
  .redss:hover{
    color: #ff8100;
  }
@media only screen and (max-width: 600px) {
    .card12{
        width: 100%;
        margin: 20px 0px;
    }
    .container-flaex {
        display: block;
        width: 100%;
        margin: 0 auto;
        gap: 28px;
        padding: 0 10px;
      }
}