#progress {
    position: fixed;
    bottom: 20px;
    right: 90px;
    height: 50px;
    width: 50px;
    place-items: center;
    border-radius: 50%;
    border: 2px solid #04ce78;
    cursor: pointer;
    z-index: 999;
}

#progress-value {
    display: block;
    height: calc(100% - 8px);
    width: calc(100% - 8px);
    background-color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 19px;
    color: #04ce78;
}