.proggressbar-area {
  background: linear-gradient(145deg, #1e2024, #23272b);
  padding: 50px;
  border-radius: 15px;
  margin-bottom: 25px;
}
.proggressbar-area span.subtitle-content {
  color: #fff;
}
.proggressbar-area h4.maintitle {
  color: #fff;
  font-size: 36px;
    font-weight: 700;
    line-height: 62px;
}
.progress-charts {
  overflow: hidden;
}
.progress-charts h6.heading {
  margin-bottom: 15px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.9;
  font-weight: 400;
}
.progress-charts .progress {
  height: 13px;
  overflow: visible;
  font-size: 14px;
  border-radius: 10px;
  padding: 3px;
  background: #212428;
  box-shadow: inset 8px 8px 16px rgba(0, 0, 0, 0.4588235294),
    inset -8px -8px 16px rgba(56, 62, 69, 0.4588235294);
}
.progress {
  margin-bottom: 13px !important;
}
.progress-charts .progress .progress-bar {
  overflow: visible;
  position: relative;
  border-radius: 14px;
  background: #6a67ce;
  background: linear-gradient(145deg, #6a67ce 0%, #fc636b 100%);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.nhdj {
  visibility: visible !important;
  animation-duration: 0.5s !important;
  animation-delay: 0.3s !important;
  animation-name: fadeInLeft !important;
}
.progress-charts .progress .progress-bar span.percent-label {
    position: absolute;
    right: 0;
    top: -22px;
    font-weight: 400;
    color: #c3cedd;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.9;
    width: min-content;
}
@media only screen and (max-width: 600px){
    .proggressbar-area {
        background: linear-gradient(145deg, #1e2024, #23272b);
        padding: 35px;
        border-radius: 15px;
        margin-bottom: 25px;
    }
    .proggressbar-area h4.maintitle {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
        line-height: 52px;
        margin-bottom: 20px;
    }
}