.service-image{
    height: 188px;
    background-image: url(../../components/image/shared_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    margin-top: -1px;
    text-align: center;
    padding-top: 36px;
}
.service-image h1{
    font-size: 33px !important;
    font-weight: 800 !important;
    color: #fff !important;
    text-align: center;
    margin-top: 28px;
    margin-bottom: 10px;
}
.container4512 {
    display: flex;
    width: 85%;
    margin: 0 auto;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  .ResCard img {
    height: 195px !important;
}
  .ResCard {
    border-radius: 4px;
    padding: 4px;
    border : 1px solid #ddd;
    margin-bottom: 0;
    margin-top : 20px;
    width: 31%;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: all .2s ease-in-out;
  }
@media only screen and (max-width: 600px){
  .container4512 {
    display: block;
    width: 100%;
    margin: 0 auto;
    gap: 30px;
    padding: 0 10px;
  }
      .ResCard {
        border-radius: 4px;
        padding: 4px;
        border :1px solid #ddd;
        margin-bottom: 0px;
        margin-top : 20px;
        width: 100%;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: all .2s ease-in-out;
      }
}
@media only screen and (max-width: 991px) {
  .service-page {
      min-width: 100%;
      padding: 0 20px;
  }
  .left-sidebar {
      width: 100%;
      order: 1;
  }
  .service-page .col-6{
      width: 100%;
  }
  .service-page .row {
      gap: 30px;
  }
  .service-page .col-5.left-sidebar,
  .service-page .col-7.right-side {
      width: 100%;
  }
}
@media only screen and (max-width:767px) {
  .service-image h1 {
      font-size: 26px !important;
  }
  .service-post.bg-cover h3,
  .box-container .service-details-list h3,
  .box-container h2.title {
      font-size: 24px;
  }
  .box-container .service-details-list .col-lg-7,
  .box-container .service-details-list .col-lg-4 {
      width: 100%;
  }
  .service-image h1 {
    font-size: 22px !important;
    padding: 60px 0;
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
 
  .service-image {
    height: auto;
    padding: 0;
  }
  .service-post.bg-cover h3, .box-container .service-details-list h3, .box-container h2.title {
    font-size: 20px;
  }
  .contact-widget-container,
  .side-widget-navigation {
      padding: 20px;
  }
  .service-post {
    padding: 60px 20px;
  }
  .service-post a {
    padding: 12px 30px;
    width: auto;
  }
}