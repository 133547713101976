p.success-msg {
  color: green;
}

p.error-msg {
  color: red;
}

.contact {
  font-size: 19px;
  color: #000;
  margin-top: 50px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.tell {
  text-align: center;
}

form.contact-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

body form.contact-form input,
body form.contact-form textarea {
  width: 100%;
  padding: 8px 10px !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  color: #000 !important;
  background: #fff !important;
  font-size: 13px;
  height: auto;
  font-weight: 400 !important;
}

body form.contact-form input::placeholder,
body form.contact-form textarea::placeholder {
  color: #415674 !important;
}

.cards-container {
  width: 85%;
  margin: 40px auto;
}

.card123 i {
  color: #fff;
  text-align: center;
  font-size: 60px;
  width: 118px;
  height: 118px;
  background: #5247ff;
  border-radius: 50%;
  margin: 35px 0 0;
  padding: 29px 0 0;
}

.card123 {
  width: 33%;
  padding: 10px !important;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #666;
  padding: 0;
  margin-bottom: 0;
  text-align: center;
}

.card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-title {
  font-size: 1.5em;
  margin: 16px 0 8px;
  font-size: 19px;
  color: #000;
  font-weight: 600;
}

.card-description {
  font-size: 15px;
  color: #666;
  text-align: center;
  line-height: 1.5;
}

.d-flax {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.left-side {
  width: 66.66666667%;
}

.right-side {
  width: 33.33333333%;
}

.contact-bg {
  background: #f1f1f1;
  position: relative;
  margin-top: 113px;
  padding-bottom: 1px;
}

.contact-bg::after {
  content: "";
  position: absolute;
  top: -60px;
  background-image: url(../../components/image/contact.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60px;
}

.section-title-bottom-line {
  background: #dfdfdf;
  border-left: 150px solid #fe8f8c;
  height: 1px;
  margin: 22px 0;
}

.left-side h2 {
  font-size: 19px;
  color: #000;
  padding: 0;
  margin: 0;
  line-height: 1.4;
  font-weight: 700;
}

.section-title-bottom-line {
  background: #dfdfdf;
  border-left: 150px solid #fe8f8c;
  height: 1px;
  margin: 22px 0;
}

.right-side h2 {
  font-size: 19px;
  color: #000;
  padding: 0;
  margin: 0;
  line-height: 1.4;
  font-weight: 700;
}

.left-side input {
  width: 100%;
  height: 45px;
  padding: 9px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 8px 0 0 0 !important;
  box-sizing: border-box;
  font-size: 15px;
}

.left-side textarea {
  width: 100%;
  height: 130px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 8px 0 0 0 !important;
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
}

.left-side textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.left-side input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.btn-primary {
  background: #5247ff !important;
  border-color: #333 !important;
  color: #fff;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn {
  display: inline-block;
  padding: 6px 24px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  letter-spacing: 2px;
}

body form.contact-form button#sub {
  display: inline-block;
  width: auto;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  padding: 15px 40px;
  margin-bottom: 0;
}

body .popup-content1 form.contact-form button#sub {
  width: 100%;
  padding: 10px;
}

.panel1 {
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

ul.no-bullet {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
}

ul.no-bullet li {
  padding: 5px 10px;
}

hr {
  margin: 15px 0 !important;
  border-top: 1px solid #eee;
}

b,
optgroup,
strong {
  font-weight: 700;
}

ul.chooseus {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.chooseus li {
  background: #f5f5f5;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 14px;
}

ul.chooseus li a {
  color: #000;
}

.error {
  color: red;
  font-size: 14px;
  margin: -15px 0px 15px 0px !important;
}

@media only screen and (max-width: 600px) {
  .tell img {
    width: 100%;
  }

  .d-flax {
    display: block;
    justify-content: space-between;
    gap: 30px;
  }

  .card123 {
    width: 100%;
    padding: 10px !important;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    border: 1px solid #666;
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .left-side {
    width: 100%;
  }

  .right-side {
    width: 100%;
  }

  ul.chooseus li {
    background: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 14px;
  }

  ul.no-bullet li {
    padding: 5px 10px;
    font-size: 14px;
  }

  .right-side h2 {
    font-size: 19px;
    color: #000;
    padding: 0;
    margin: 0;
    line-height: 1.4;
    font-weight: 700;
    margin-top: 20px;
  }

  .left-side input {
    margin: 10px 0;
  }

  .left-side textarea {
    margin: 10px 0;
  }

  .contact {
    font-size: 15px;
    color: #000;
    margin-top: 31px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
  }

  .cards-container {
    width: 100%;
    padding: 0 10px;
  }
  form.contact-form {
    padding: 20px;
  }
  body form.contact-form button#sub {
    padding: 10px 20px;
    font-size: 14px;
  }
}