.cms-blog .cms-blog-grid-section {
    padding: 60px 0;
}

.cms-blog .cms-blog-grid-section .cms-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.cms-blog .cms-blog-grid-wrap {
    width: 75%;
}

.cms-blog .cms-blog-grid {
    width: 48%;
}

.cms-blog .cms-blog-row {
    display: flex;
    gap: 40px;
    justify-content: space-between;
}

.cms-blog .single-blog .blog-image a img {
    width: 100%;
    transition: all 0.3s linear;
    border-radius: 10px;
}

.cms-blog .single-blog:hover .blog-image a img {
    transform: scale(1.1);
}

.cms-blog .single-blog .blog-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.cms-blog .single-blog .blog-image .top-meta {
    position: absolute;
    top: 35px;
    left: 30px;
}

.cms-blog .single-blog .blog-image .top-meta .date {
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    line-height: 12px;
    padding: 10px 16px;
    font-family: "Barlow", sans-serif;
    background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
}

.cms-blog .single-blog .blog-image .top-meta .date span {
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    display: block;
}

.cms-blog .single-blog .blog-content {
    padding: 25px 30px 40px;
    background: #ffffff;
    width: 90%;
    position: relative;
    left: 50%;
    bottom: 10px;
    transform: translateX(-48%);
    margin-top: -40px;
    box-shadow: 0 0 6px #ddd;
}

.cms-blog .single-blog .blog-content .title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: #0e0e0e;
    padding-right: 15px;
    margin-top: 5px;
}

/* .cms-blog .single-blog .blog-content {
      padding: 25px 30px 40px;
      background: #ffffff;
      width: 70%;
      position: relative;
      left: 48%;
      bottom: 10px;
      transform: translateX(-48%);
      margin-top: -40px;
  } */
.cms-blog .blog-meta {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.cms-blog .blog-meta span {
    display: flex;
    align-items: center;
    gap: 4px;
}

.cms-blog .blog-meta span a {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    color: #999999;
    text-decoration: none;
}

.cms-blog .single-blog .blog-content .title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    padding-right: 15px;
    margin-top: 15px;
}

.cms-blog .single-blog .blog-content .title a {
    color: #0e0e0e;
    text-decoration: none;
    height: 60px;
    overflow: hidden;
    display: block;
    font-size: 20px;
}

.cms-blog .single-blog .blog-btn .blog-btn-link {
    font-size: 12px;
    font-weight: 600;
    color: #086ad8;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.3s linear;
    text-decoration: none;
    font-family: "Barlow", sans-serif;
}

.cms-blog .blog-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 15px;
}

.cms-blog .cms-pagination {
    margin-top: 60px;
}

.cms-blog .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    justify-content: center !important;
}

.cms-blog .cms-pagination .pagination li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    display: inline-block;
    transition: all 0.3s linear;
    text-decoration: none;
}

.cms-blog .cms-pagination .pagination li a.active {
    background-color: #086ad8;
    color: #ffffff;
}

.cms-blog .cms-pagination svg {
    margin-top: 9px;
}

.cms-blog .sidebar-widget-1 form.search-form {
    overflow: hidden;
    width: 100%;
    position: relative;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]),
textarea {
    box-shadow: none;
    color: #415674;
    background: #f6f7f9;
    margin-bottom: 0;
    padding: 10px 25px;
    max-width: 100%;
    width: 100%;
    border: none;
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    transition: all 0.3s linear;
    box-sizing: border-box;
}

.cms-blog .blog-sidebar form.search-form input {
    width: 100%;
    border: 0;
    padding-right: 35px;
    background: #f2f4f7;
    color: #415674;
    font-weight: 700;
    outline: none;
    overflow: hidden;
}

.cms-blog form.search-form button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    color: #111111;
    cursor: pointer;
    transition: all 0.3s linear;
}

.cms-blog .cms-blog-siderbar {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: sticky;
    top: 20px;
    bottom: 20px;
    padding: 20px;
    height: fit-content;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cms-blog .cms-blog-flex-box {
    position: relative;
    padding: 10px 10px 10px 10px;
    text-align: center;
    background-color: #389E46;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.cms-blog .flex-box-contents {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #5247ff;
    padding: 8px 8px 8px 8px;
    transform: scale(0);
    transition: 0.4s;
}

.cms-blog .cms-blog-flex-box:hover .flex-box-contents {
    transform: scale(1);
}

.cms-blog .cms-blog-flex-box-icon svg {
    width: 70px;
    height: 70px;
    fill: #fff;
}

.cms-blog .cms-blog-flex-box-title h2 {
    color: #fff;
    font-size: 16px;
}

.cms-blog h4.flex-box-content-title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
}

.cms-blog p.flex-box-content {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
}

.cms-blog a.flex-box-content-button {
    background-color: #69727d00;
    font-size: 14px;
    padding: 12px 22px;
    border-radius: 3px;
    color: #fff;
    transition: all .3s;
    text-decoration: none;
    border: 2px solid #fff;
    line-height: 1;
    margin-top: 20px;
}

.cms-blog .flex-box .web-designing {
    background: #00C4F7;
}

.cms-blog .flex-box .optimization {
    background: #EE3816;
}

.cms-blog .flex-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cms-blog .single-blog .blog-image .top-meta .date {
    background: linear-gradient(-180deg, #5247ff, #7141b1) !important;
}

.cms-blog .single-blog .blog-content {
    padding: 25px 30px 25px !important;
}

.cms-blog .single-blog .blog-btn .blog-btn-link {
    color: #5247ff;
}

.cms-blog span.post-count {
    background: #5247ff21;
    color: #5247ff;
}

.sidebar-widget .category-list li:hover span.post-count {
    background-color: #5247ff;
}

.cms-blog .cms-pagination .pagination li a.active {
    background-color: #5247ff;
}

.cms-blog .blog-content h3.title a {
    transition: 0.4s;
}

.cms-blog .blog-content h3.title a:hover {
    color: #5247ff !important;
}

.cms-blog .blog-content .blog-btn svg,
.cms-blog .blog-content .blog-meta svg {
    color: #5247ff;
    transition: 0.5s;
}

.cms-blog .cms-blog-grid:hover .blog-content .blog-btn svg {
    margin-left: 7px;
}

.cms-blog .single-blog .blog-content .title {
    height: 60px;
    overflow: hidden;
    text-decoration: none;
}

.cms-blog .blog-meta span {
    color: #999999;
    font-size: 14px;
    text-transform: capitalize;
}

@media only screen and (max-width: 1024px) {


    .cms-blog .single-blog .blog-content {
        padding: 25px 20px 40px;
        width: 75%;
    }

    .cms-blog .single-blog .blog-content .title {
        padding-right: 0;
    }
}

@media only screen and (max-width: 991px) {
    .cms-blog .single-blog .blog-content {
        padding: 20px 20px 40px;
        width: 78%;
    }
    .cms-blog .cms-blog-grid-wrap {
        width: 70%;
    }
    .cms-blog .cms-blog-siderbar {
        width: 30%;
    }
    .cms-blog .single-blog .blog-content {
        width: 100%;
        margin: 0;
        position: unset;
        transform: unset;
    }
    .cms-blog .single-blog .blog-image a img {
        height: 200px;
        object-fit: cover;
    }
    .cms-blog .single-blog .blog-content {
        width: 100%;
        margin: 0;
        position: unset;
        transform: unset;
        box-shadow: unset;
        padding: 15px !important;
    }
    .cms-blog .cms-blog-grid {
        border: 1px solid #ddd;
        border-radius: 6px;
    }
    .cms-blog .single-blog .blog-content .title a {
        font-size: 18px;
        line-height: 22px;
        height: 50px;
    }
    .cms-blog .single-blog .blog-content .title {
        margin-top: 5px;
        margin-bottom: 5px;
        height: 50px;
    }
    .cms-blog .cms-blog-row{
        gap: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .cms-blog .cms-blog-grid-section {
        padding: 50px 0;
        margin: 0 10px;
    }

    .cms-blog .cms-blog-grid {
        width: 48.50%;
    }

    .cms-blog .cms-pagination {
        margin-top: 50px;
    }

    .cms-blog .cms-blog-row {
        display: flex;
        gap: 40px;
        flex-direction: column;
    }

    .cms-blog .cms-blog-grid-wrap,
    .cms-blog .cms-blog-siderbar {
        width: 100%;
        box-sizing: border-box;
    }

    .cms-blog .flex-box {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .cms-blog .cms-blog-flex-box {
        width: 33.33%;
        height: 200px;
    }

    .cms-blog .cms-blog-flex-box-icon svg {
        width: 60px;
        height: 60px;
    }

    .cms-blog .cms-blog-grid-section {
        margin: 0;
        padding: 50px 0;
    }

    .cms-blog .cms-blog-flex-box {
        height: 200px;
        width: 50%;
    }
}

@media only screen and (max-width: 685px) {
    .cms-blog .cms-blog-grid {
        width: 100%;
    }
}

@media only screen and (max-width: 660px) {
    .cms-blog .cms-blog-grid {
        width: 100%;
    }

    .cms-blog .cms-blog-siderbar {
        padding: 20px;
    }

    .cms-blog .flex-box {
        flex-direction: column;
    }

    .cms-blog .cms-blog-flex-box {
        width: 100%;
    }
}

@media only screen and (max-width: 414px) {
    .cms-blog .cms-blog-grid-section {
        padding: 40px 0;
    }

    .cms-blog .single-blog .blog-content .title {
        font-size: 20px;
        line-height: 26px;
    }

    .cms-blog .cms-pagination {
        margin-top: 30px;
    }

    .cms-blog .cms-blog-row {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
}