





.whatsapp-btn {
    background-color: #1bd741;
    color: white;
    padding: 10px 10px;
    text-decoration: none;
    margin-top: 20px;
    align-items: center;
    display: flex;
    gap: 10px;
    border-radius: 50%;
}
.whatsapp-btn .icon-what{
    font-size: 30px;
}
.whatfixed{
    position: fixed;
    left: 24px;
    bottom: 30px;
    z-index: 999;
}
@-webkit-keyframes video-out {
    100% {
        top: -30px;
        right: -30px;
        bottom: -30px;
        left: -30px;
        opacity: 0
    }
}

@keyframes video-out {
    100% {
        top: -30px;
        right: -30px;
        bottom: -30px;
        left: -30px;
        opacity: 0
    }
}
.whatsapp-btn::before {
    content: '';
    position: absolute;
    box-shadow: #1bd741 0 0 20px;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation-name: video-out;
    animation-name: video-out;
    opacity: 1;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
