@media (min-width: 992px) {
    .container {
        width: 100%;
    }
    
}

@media (min-width: 1200px) {
    .container {
        width:1170px;
        margin: auto;
    }
    .larg-affilate {
        display: block;
        width: 55%;
        margin: auto;
    }
}
.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}
p {
    margin: 0 0 10px;
}
ol, ul {
    margin-bottom: 10px;
}
.text-center {
    text-align: center;
}
.our-portfolio,
#thumbnail-style,
.balance_time_header .container,
.count_section_header,
.lets_work_header .container {
    padding: 80px 0;
}
.our-portfolio{
    padding-top: 0;
}
h2.text-center.sec-review-ti, h3.text-center.sec-review-ti, h4.text-center.sec-review-ti {
    line-height: 48px;
    font-size: 34px;
    font-weight: 600;
    letter-spacing: -.05em;
    text-align: center;
    position: relative;
    color: #000;
}


/* slider */
.leftArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    left: 0px;
    font-size: 26px;
    color: rgb(255, 255, 255);
    z-index: 1;
    cursor: pointer;
    width: 51px;
    background: rgba(37, 39, 52, 0.68);
    text-align: center;
}
.rightArrowStyles{
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    right: 0px;
    font-size: 26px;
    color: rgb(255, 255, 255);
    z-index: 1;
    cursor: pointer;
    background: rgba(37, 39, 52, 0.68);
    text-align: center;
    width: 51px;
}
.sliderStyles {
    position: relative;
    height: 100%;
}


.res.hero-slider {
    z-index: -1;
}
/*card*/
h1.title-box {
    line-height: 48px;
    font-size: 34px;
    font-weight: 600;
    letter-spacing: -.05em;
    text-align: center;
    position: relative;
    color: #000;
    width: 60%;
    margin: 60px auto -20px;
}
h1.title-box:after {
    content: " ";
    width: 120px;
    height: 2px;
    background: #ff1732;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: .25rem;
}
div#thumbnail-style .col-md-4 {
    width: 33.33333333%;
    padding-right: 15px;
    padding-left: 15px;
}
div#thumbnail-style {
    display: flex;
    flex-wrap: wrap;
}
div#thumbnail-style .thumbnail {
    border: 1px solid #ddd;
    box-shadow: 7px 9px 5px #bcbbcf;
    padding: 10px;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    display: block;
    text-align: center;
}
div#thumbnail-style .thumbnail:hover {
    background: #5247ff33;
}
div#thumbnail-style .thumbnail img {
    width: 75px;
}
.thumbnail .caption {
    padding: 9px;
    color: #333;
}
#thumbnail-style .thumbnail h1, #thumbnail-style .thumbnail h2, #thumbnail-style .thumbnail h3 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}
div#thumbnail-style p {
    min-height: 80px;
    line-height: 20px;
}
body a.btn-primary {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    background: #5247ff !important;
    padding: 8px 15px;
}




/*use-technology-section*/
section.use-technology {
    padding: 80px 0;
    background: #ebebeb;
}
.use-technology-section ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}
.use-technology-section li {
    box-shadow: 0 0 9px 1px #ddd;
    padding: 10px;
    margin: 10px;
    list-style: none;
    width: 23%;
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 15px;
    color: #000;
    background: #fff;
    border: 1px solid #ddd;
}
p.des-choose {
    text-align: center;
    font-size: 16px;
    margin-bottom: 22px;
}


/*Let's Work Together*/

.copyright_section, .footer_inner_main, .lets_work_header, header {
    background: #252734;
}
.lets_work_header .lets_work_items {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-top: 50px;
}
.lets_work_header .lets_work_title h2 {
    color: #fff;
    line-height: 48px;
    font-size: 34px;
    /* font-size: 52px; */
    font-weight: 600;
    letter-spacing: -.05em;
    text-align: center;
    padding-bottom: 30px;
    position: relative;
}
.balance_time_header .balance_time_title h2:after, .lets_work_header .lets_work_title h2:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 120px;
    bottom: 8px;
    left: 0;
    right: 0;
    margin-right: 2%;
    border-radius: .25rem;
    background-color: #ff1732;
    margin: auto;
}
.lets_work_header .lets_work_lead p {
    color: #ffffff80;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
}
.lets_work_item {
    border-left: 1px solid #ffffff1a;
    padding-left: 20px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    width: 33.33%;
}
.lets_work_item:first-child {
    border: 0;
    padding-left: 0;
}
.lets_work_header .lets_work_items .lets_work_item .content h2 {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -.03em;
    margin: 0;
}
.lets_work_header .lets_work_items .lets_work_item .content p {
    color: #ffffff80;
    font-size: 14px;
    margin-top: 15px;
    line-height: 20px;
}


/*Balance Your Time*/

.balance_time_header, .footer_inner_item {
    position: relative;
}
.balance_time_header .balance_time_title h2 {
    line-height: 48px;
    font-size: 34px;
    font-weight: 600;
    letter-spacing: -.05em;
    text-align: center;
    padding-bottom: 30px;
    position: relative;
    color: #000;
    width: 60%;
    margin: auto;
}

.balance_time_header .moving_image img {
    -webkit-animation: 2s linear infinite mk_tossing;
    -moz-animation: 2s linear infinite mk_tossing;
    -ms-animation: mk_tossing 2s infinite linear;
    -o-animation: 2s linear infinite mk_tossing;
}

@keyframes mk_tossing {
    0%,100% {
        -webkit-transform: rotate(-6deg);
        transform: rotate(-6deg)
    }

    50% {
        -webkit-transform: rotate(6deg);
        transform: rotate(6deg)
    }
}

.balance_time_header .moving_image {
    position: absolute;
    top: -10%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: -1
}

.balance_time_header .balance_time_items {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.balance_time_header .balance_time_items .balance_time_item {
    width: 25%;
    box-shadow: 0 0 14px #ddd;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 15px;
}
.balance_time_header .balance_time_items .balance_time_item,.count_section_item {
    background-image: url(https://www.fahimshakir.com/img/service-pattern.png.webp);
    background-repeat: no-repeat;
}
.balance_time_header .image_loaded {
    display: flex;
    justify-content: space-around;
}
.balance_time_header .balance_time_item .content h3 {
    font-weight: 700;
    font-size: 20px;
    color: #000;
    padding: 10px 0;
    text-align: center;
    margin-top: 0;
    margin-bottom: 5px;
}
.balance_time_header .balance_time_item .content p {
    font-size: 14px;
    color: #000;
    line-height: 1.5em;
    text-align: center;
}


/*What our clients have to say*/
.testimonialsection {
    padding: 50px 0 25px;
}
#thumbnail-style2 h3, #thumbnail-style2 p {
    color: #fff;
}
#thumbnail-style2 h3, #thumbnail-style2 p {
    color: #fff;
}



/*Why Choose Us*/
section.choose-us {
    padding: 80px 0;
    background: #00000014;
}
h2.text-center.sec-review-ti, h3.text-center.sec-review-ti, h4.text-center.sec-review-ti {
    line-height: 48px;
    font-size: 34px;
    font-weight: 600;
    letter-spacing: -.05em;
    text-align: center;
    position: relative;
    color: #000;
}
p.des-choose {
    text-align: center;
    font-size: 16px;
}
.choose-us-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
}
.choose-us-section .card {
    width: 13%;
    border-radius: 5px;
    box-shadow: 0 0 12px #ddd;
    padding: 20px 15px;
    text-align: center;
    background: #fff;
    border: 1px solid #5247ff;
    margin: 0 0 10px 10px;
    color: #000;
}
.choose-us-section .card:hover {
    background-image: linear-gradient(100deg, #7474bf 0, #348ac7 100%);
    color: #fff;
}
.choose-us-section .card:hover i, .choose-us-section .card:hover svg {
    background: #000;
    color: #fff;
}
.choose-us-section .title {
    font-weight: 600;
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
}
.choose-us-section i, .choose-us-section svg {
    font-size: 24px;
    background: #5247ff2b;
    border-radius: 50px;
    padding: 8px;
    color: #5247ff;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

/*CountSection*/

#thumbnail-style, .balance_time_header .container, .count_section_header, .lets_work_header .container {
    padding: 80px 0;
}
.count_section_items {
    display: flex;
    gap: 20px;
}
.count_section_item {
    width: 33.33%;
    padding: 20px;
    border: 2px solid #5247ff;
    border-radius: 12px;
    transition: .3s;
    box-shadow: 6px 6px 6px #5247ff;
    color: #000;
}
.balance_time_header .balance_time_items .balance_time_item, .count_section_item {
    background-repeat: no-repeat;
}
.count_section_items strong {
    text-align: center;
    display: block;
}
.count_section_header .count_section_item .content h3 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-family: Verdana, Helvetica, Arial, sans-serif;
    margin-top: 10px;
    margin-bottom: 0;
}


/*testimonial*/
.testimonialsection {
    padding: 50px 0 25px;
    background: url(https://www.fahimshakir.com/img/testimonials_section_bg.jpg) top center fixed;
}

.testimonialsection h3+p {
    text-align: center;
    font-size: 20px;
    color: #ffa131 !important;
}
.testimonialsection h3.text-center {
    color: #fff;
    font-size: 24px;
}
.testimonialsection hr.small {
    border: solid #ff1732;
    border-width: 2px 0 0;
    clear: both;
    margin: 30px auto 20px !important;
    height: 0;
    max-width: 120px;
}
p.experience:before {
    content: "❝";
    font-size: 50px;
    margin-bottom: 10px;
    display: block;
    color: #fff;
}
.img-circle {
    border-radius: 50%;
}
.slick-slide img.img-circle {
    margin: auto;
}
.testimonialsection p.experience {
    color: #fff;
    text-align: center;
    width: 75%;
    margin: auto;
    padding: 20px 0;
}
.container.margin.uniqe {
    padding-bottom: 50px;
}
.testimonialsection .slick-dots li.slick-active button:before {
    background: #fff;
}
.testimonialsection .slick-dots li button:before {
    background: none;
    border: 1px solid #fff;
    content: "";
    border-radius: 50px;
    height: 10px;
    width: 10px;
}
.testimonialsection .slick-prev:before, 
.testimonialsection .slick-next:before {
    color: #fff;
    font-size: 30px;
}



/*video*/
.video-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 35px;
    margin: 20px 0;
}
.video-item iframe {
    height: 400px;
    width: 100%;
}
.video-item {
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 0 7px #ddd;
    border-radius: 10px;
    background: #000;
}
.video-container {
    padding: 0 0 20px;
    background: url(https://www.fahimshakir.com/img/testimonials_section_bg.jpg) top center fixed;
}



.our-portfolio {
    text-align: center;
}

.our-portfolio .haxx {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 10px;
}

.our-portfolio .haxx .maidcard {
    width: 24%;
    position: relative;
}

.our-portfolio .haxx .maidcard img {
    width: 100%;
    object-fit: scale-down;
    background: black;
    height: auto;
}

.trans-color {
    align-items: center;
    background-color: #5247ffb3;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 1s;
    visibility: hidden;
    width: 100%;
}
.Main-mard, .maidcard {
    border: 1px solid #000;
    height: auto;
    margin-bottom: 30px;
    margin-top: 20px;
    position: relative;
    text-align: center;
    border-radius: 5px;
}
.Main-mard img, .maidcard img {
    height: 290px;
    position: relative;
    width: 100%;
}
.viewhere {
    background-color: #fff0;
    border: 1px solid #5247ff;
    border-radius: 10px;
    color: #5247ff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 40px;
    display: inline-block;
    margin-bottom: 40px;
    transition: .5s;
}
.viewhere:hover {
    background: #5247ff;
    color: #fff;
    transition: .5s;
}
.Main-mard p, .maidcard p {
    font-family: Poppins, Sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1em;
    padding: 15px 0 0;
    text-align: center;
}

.Main-mard:hover .trans-color, .maidcard:hover .trans-color {
    opacity: 1;
    visibility: visible;
}
.fg-item-icon-inner {
    background: #fff;
    border-radius: 100px;
    color: #252734;
    display: block;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    transition: .3s;
    width: 50px;
}
.fg-item-icon-inner:hover {
    background: #252734;
    color: #fff;
}

.video-container button.slick-arrow.slick-prev {
    left: 0 !important;
}
.video-container  button.slick-arrow.slick-next{
    right: 0 !important;
}
.slick-slide img {
    margin: auto;
}

 
@media only screen and (max-width: 991px) {
    .video-container {
        padding: 0 0 20px;
    }
    .viewhere {
        margin: 20px 0;
        font-size: 14px;
    }
}
@media only screen and (max-width: 767px) {
    .video-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
    }
    
    .prot-items button.slick-arrow.slick-next::before,
    .prot-items button.slick-arrow.slick-prev::before,
    .container.margin.uniqe button.slick-arrow.slick-next::before,
    .container.margin.uniqe button.slick-arrow.slick-prev::before,
    .video-container  button.slick-arrow.slick-next::before,
    .video-container button.slick-arrow.slick-prev::before {
        background: #fff;
        font-size: 28px;
        color: #5247ff;
        border-radius: 50px;
    }
    .prot-items {
        padding: 0 20px;
        margin-bottom: 20px;
    }
    .prot-items  button.slick-arrow.slick-prev{
        left: 0 !important;
    }
    .prot-items button.slick-arrow.slick-next {
        right: 0 !important;
    }
    .viewhere{
     padding: 8px 25px;
    }

    .leftArrowStyles, .rightArrowStyles {
        display: none;
    }
}


@media only screen and (max-width: 1024px) {
    section.use-technology,
    section.choose-us,
    #thumbnail-style,
    .balance_time_header .container,
    .count_section_header,
    .lets_work_header .container,
    #thumbnail-style,
    .balance_time_header .container,
    .count_section_header,
    .lets_work_header .container {
        padding: 60px 10px;
    }
 
    .use-technology-section li {
        width: 31%;
        margin: 0;
    }
    .testimonialsection {
        padding: 50px 10px 25px;
    }
    .testimonialsection p.experience {
        width: 92%;
    }
    .choose-us-section .card {
        width: 32%;
        margin: 0;
    }
    .use-technology-section ul,
    .choose-us-section {
        justify-content: space-between;
        row-gap: 20px;
    }
    div#thumbnail-style .col-md-4 {
        padding: 0 10px;
    }
    section.use-technology .container,
    .video-container .container.p-0,
    .count_section_header .container,
    section.choose-us .container {
        padding: 0;
    }
    .balance_time_header .balance_time_title h2,
    h1.title-box {
        width: 100%;
    }
    .count_section_header .count_section_item .content h3 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 991px) {
    .choose-us-section .card {
        width: 31%;
    }
    .use-technology-section ul {
        justify-content: space-between;
    }
    .use-technology-section li {
        width: 49%;
        margin: 0;
        margin-bottom: 10px;
    }
    .our-portfolio .haxx .maidcard {
        width: 49%;
        margin-bottom: 0px;
    }
    .video-grid{
        grid-gap: 20px;
    }
    .balance_time_header .moving_image img {
        width: 30%;
        height: auto;
    }
    
    .our-portfolio{
        padding: 0;
        overflow: hidden;
    }
}

@media only screen and (max-width: 767px) {
    #thumbnail-style, .balance_time_header .container, .count_section_header, .lets_work_header .container,
    section.use-technology, section.choose-us, #thumbnail-style,
    .balance_time_header .container, .count_section_header,
    .lets_work_header .container, #thumbnail-style, .balance_time_header .container,
    .count_section_header, .lets_work_header .container {
        padding: 40px 10px;
    }
    .sec-review-ti, .balance_time_header .balance_time_title h2, .lets_work_header .lets_work_title h2, h2.text-center.sec-review-ti, h3.text-center.sec-review-ti, h4.text-center.sec-review-ti {
        font-size: 24px !important;
        line-height: 28px !important;
    }
    .count_section_items {
        flex-wrap: wrap;
    }
    .count_section_item {
        width: 100%;
    }
    .count_section_header .count_section_item .content h3 {
        font-size: 20px;
    }
    .video-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }
    section.choose-us .container {
        padding: 0;
    }
    .balance_time_header .container {
        padding: 40px 10px;
    }

    .balance_time_header .balance_time_items .balance_time_item,.choose-us-section .card {
        width: 48%;
    }
    .balance_time_items.balance_time_items_botton {
        flex-wrap: wrap;
    }
    .balance_time_items_botton {
        margin-top: 360px;
    }
    .balance_time_header .moving_image img {
        width: 46%;
    }
    .balance_time_header .moving_image {
        top: 80px;
    }
    .balance_time_header .balance_time_item .content h3 {
        font-size: 18px;
    }
    div#thumbnail-style .col-md-4{
        width: 100%;
        margin-bottom: 20px;
    }
    .lets_work_header .lets_work_items{
        flex-wrap: wrap;
    }
    .lets_work_item {
        width: 100%;
        padding: 0;
        border: 0;
    }
    .lets_work_item .content {
        width: 80%;
    }
    .item_cloums {
        width: 20%;
    }
    .lets_work_item:nth-child(2) {
        padding: 10px 0;
    }
    .lets_work_header .lets_work_lead p,
    p.des-choose{
        font-size: 14px;
        line-height: 26px;
    }
    .viewhere{
        font-size: 16px;
    }
    .use-technology-section li {
        width: 49%;
        background-color: #fff;
        flex-direction: column;
        justify-content: center;
        height: 130px;
        align-items: center;
        gap: 10px;
        padding: 8px 10px;
        text-align: center;
        margin: 0;
        display: flex !important;
    }
    h1.title-box{
        font-size: 24px;
        line-height: 28px;
    }
    .use-technology-section li img {
        height: 37px;
        object-fit: cover;
        width: 37px;
    }
    
    .balance_time_header .moving_image img {
        width: 60%;
        height: auto;
    }
    .container.f-margin.contan {
        padding: 0 10px !important;
    }
}



@media only screen and (max-width: 639px) {
    .balance_time_header .moving_image {
        position: unset;
        padding: 40px 0;
    }
    .balance_time_header .balance_time_items {
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 0;
    }
    .choose-us-section{
        justify-content: space-between;
    }
    .balance_time_header .balance_time_items .balance_time_item, .choose-us-section .card {
        width: 48%;
        margin: 0;
        margin-bottom: 3%;
    }
    .use-technology-section button, .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
        display: none !important;
    }
    .use-technology-section li {
        width: 90% !important;
        margin: auto;
    }
    h1.title-box {
        font-size: 20px;
        line-height: 24px;
    }
    
}
@media only screen and (max-width: 414px) {
    .sec-review-ti, .balance_time_header .balance_time_title h2, .lets_work_header .lets_work_title h2, h2.text-center.sec-review-ti, h3.text-center.sec-review-ti, h4.text-center.sec-review-ti {
        font-size: 20px !important;
        line-height: 26px !important;
    }
    .balance_time_header .balance_time_items {
        flex-wrap: wrap;
    }
    .balance_time_header .balance_time_items .balance_time_item{
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
    .choose-us-section .card {
        width: 48%;
        margin: 0;
        margin-bottom: 20px;
    }
    .item_cloums {
        width: 100%;
    }
    .use-technology-section button,
    .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
        display: none !important;
    }
    .lets_work_item {
        display: block;
        text-align: center;
    }
    .lets_work_item .content{
        width: 100%;
    }
    .use-technology-section li {
        width: 100%;
    }
    .our-portfolio .haxx .maidcard {
        height: auto;
        margin: 0;
        margin-bottom: 20px;
    }
    .Main-mard p, .maidcard p,
    .viewhere {
        font-size: 14px;
    }
    .video-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
    }
    .Main-mard img, .maidcard img {
        height: 150px;
    }
}





.custom-arrow {
    display: block;
    background-color: #5247ff;
    padding: 9px 4px 9px 4px ;
    cursor: pointer;
    color: white;
    text-align: center;
    line-height: 1px;
    border-radius: 50px;
    font-size: 0;
}

  .custom-arrow.custom-next:before {
    content: "→";
    font-size: 22px;
    line-height: 10px;
    position: relative;
    top: -2px;
}
  
.custom-arrow.custom-prev:before {
    content: "←";
    font-size: 22px;
    line-height: 10px;
}
  .custom-next {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .custom-prev {
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  

.service-page .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.service-page .col-4 {
    width: 28%;
}
.service-page .col-6 {
    width: 69%;
}
.service-page .left-sidebar-container {
    background: #dddddd24;
    padding: 5px 0;
}
 
.service-page ul.nav-link li {
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}
.service-page ul.nav-link li:last-child {
    border: 0;
}
.service-page ul.nav-link li  span,
.service-page ul.nav-link li a{
    padding: 10px 20px;
    display: block;
    color: #000;
}
.service-page ul.nav-link li span {
    font-weight: 600;
}
.box-container  img.featured-image {
    width: 100%;
}
.box-container h2 {
    font-size: 20px;
}
.box-container h3 {
    font-size: 18px;
}
.box-container h2.title {
    font-size: 28px;
    margin-top: 15px;
}
.left-sidebar-container {
    border-right: 1px solid #ddd;
}


.service-bg {
    background-color: #ebebeb;
    padding: 80px 50px;
  }
  
  .service-bg ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .service-bg li {
    border-radius: 10px;
    letter-spacing: 1px;
    margin: 0;
    box-shadow: 1px 3px 2px 0px #003ea6;
  }
  .service-bg li {
    margin: 10px;
    list-style: none;
    width: 23%;
    gap: 10px;
    line-height: 1.2;
    background: #fff;
    font-size: 15px;
    border: 1px solid #ddd;
  }
  .service-bg li a{
    padding: 5px 20px;
    min-height: 50px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .containerve {
    margin: 0 auto;
    width: 95%;
}
.our-service .service-bg {
    margin: 60px 0;
}
.service-bg li a:hover {
    background: #ffc107;
    border-radius: 8px;
}
.service-page ul.nav-link li a:hover,
.service-page ul.nav-link li span {
    background: #5247ff;
    color: #fff;
}
.divider {
    border: 1px solid #ddd;
    margin: 35px 0;
}




/* service page */

.side-widget-navigation {
    background: linear-gradient(90deg, rgba(32, 217, 161, 0.08) 0%, rgba(95, 57, 255, 0.08) 100%);
    border-radius: 10px;
    padding: 40px;
}
h2.side-title {
    display: inline-block;
    font-size: 23px;
    color: #5f39ff;
    position: relative;
    z-index: 1;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 0;
}

h2.side-title:before {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0;
    width: 60%;
    height: 2px;
    background: #5f39ff;
}
.conatainer.service-page {
    max-width: 1170px;
    width: 100%;
    margin: auto;
}
.service-page .col-5.left-sidebar {
    width: 32%;
}
.service-page .col-7.right-side {
    width: 65%;
}
ul.menu-all-services span {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    color: rgb(114, 114, 114);
    cursor: pointer;
}
ul.menu-all-services li {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}
.challenges-list {
    color: rgb(118, 118, 118);
    line-height: 20px;
}
.service-post{
    background: url(../../components/image/service.jpg);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 90px 40px;
    border-radius: 10px;
    margin: 20px 0;

}
.service-post.bg-cover h3 {
    font-size: 28px;
    margin-bottom: 30px;
    margin-top: 0;
    color: #fff;
    text-transform: capitalize;
}
.service-post a {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 18px 55px;
    border-radius: 0;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
    background: #fff;
    color: #2a2833;
}
.contact-widget-container {
    position: relative;
    background: -webkit-gradient(linear, left top, right top, from(rgba(32, 217, 161, 0.08)), to(rgba(95, 57, 255, 0.08)));
    background: linear-gradient(90deg, rgba(32, 217, 161, 0.08) 0%, rgba(95, 57, 255, 0.08) 100%);
    border-radius: 10px;
    padding: 40px;
    overflow: hidden;
}
.contact-info .info-item .title {
    color: #705fff;
}
.contact-info .info-item {
    padding-bottom: 0;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    gap: 15px;
    margin-bottom: 25px;
}
.contact-info .info-item .title {
    color: #705fff;
    font-size: 16px;
    line-height: 23px;
    font-weight: 700;
    margin: 0;
}
.contact-info  ul.dot-style {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
}

.contact-info ul li a {
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 50%;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #d6d1ff;
    color: #5f39ff;
}
.contact-info a {
    color: #727272;
    font-size: 14px;
    line-height: 16px;
}
.contact-info .icon {
    max-width: 35px;
    height: 35px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    background-color: #d6d1ff;
    border-radius: 50%;
    font-size: 14px;
    line-height: 1;
    color: #5f39ff;
    margin-top: -5px;
}

.conatainer.service-page > .row {
    justify-content: space-between;
}

.faq-accordion .details {
    background-color: #fff;
    -webkit-box-shadow: var(---box-shadow);
    box-shadow: 0px 0px 60px 0px rgba(4, 23, 26, 0.08);
    padding: 18px 30px;
    border-radius: 0;
    border: 0;
    margin-top: 20px;
}
.faq-accordion .details .summary {
    font-weight: 600;
    color: #2a2833;
    letter-spacing: -.2px;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    padding: 10px 45px;
    position: relative;
}
ul.menu-all-services > li > span:after,
.faq-accordion .details .summary:before {
    content: "\f067";
    font-family: "Font Awesome 6 Brands";
    font-weight: 300;
    color: #ffffff;
    position: absolute;
    top: 10px;
    left: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    background: #5247ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
ul.menu-all-services li span:after{
    position: unset;
}
ul.menu-all-services > li.menu-item.open > span:after,
.faq-accordion .details.open .summary:before {
    content: "-";
}
.faq-accordion .details p {
    border-top: 1px solid #d9d9d9;
    margin-top: 20px;
    padding-top: 20px;
}
.faq-accordion .details p {
    display: none;
}
.faq-accordion .details.open p{
    display: block;
}
.excerpt-container {
    margin: 15px 0;
}
.goal-list li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #fd547d;
    font-size: 18px;
    margin-right: 15px;
}
.box-container .service-details-list h3 {
    font-size: 26px;
    color: #000;
    margin-bottom: 20px;
}
.box-container .service-details-list li {
    margin-bottom: 10px;
}
.box-container .service-details-list {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
.service-page ul.menu-all-services li span,
.service-page ul.menu-all-services li a {
    padding: 5px;
    font-size: 14px;
    line-height: 18px;
    color: #727272;
}
.service-page ul.menu-all-services ul > li > span{
    color: #5247ff;
    background: none;
}
.service-page ul.menu-all-services li {
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.service-page ul.menu-all-services ul {
    padding-left: 5px;
    padding-top: 15px;
    /* max-height: 350px;
    overflow: auto; */
}

ul.menu-all-services {
    overflow: auto;
    height: 400px;
}

.service-page ul.menu-all-services::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .service-page ul.menu-all-services::-webkit-scrollbar-track {
    background: #ddd; 
  }
   
  /* Handle */
  .service-page ul.menu-all-services::-webkit-scrollbar-thumb {
    background: #948fde; 
  }
  
  /* Handle on hover */
  .service-page ul.menu-all-services::-webkit-scrollbar-thumb:hover {
    background: #948fde; 
  }
@media (min-width: 992px) {
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}
 
@media only screen and (max-width:767px) {
    .service-image h1 {
        font-size: 26px !important;
    }
    .service-post.bg-cover h3,
    .box-container .service-details-list h3,
    .box-container h2.title {
        font-size: 24px;
    }
    .box-container .service-details-list .col-lg-7,
    .box-container .service-details-list .col-lg-4 {
        width: 100%;
    }

    .map-content{
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .service-bg ul {
        display: flex;
        flex-wrap: wrap;
    }
    .service-bg li {
        align-items: center;
        background: #fff;
        border: 1px solid #ddd;
        color: #000;
        display: flex;
        font-size: 11px;
        gap: 0;
        line-height: 1.2;
        list-style: none;
        width: 43%;
    }
    .service-bg {
        background-color: #ebebeb;
        padding: 45px 0;
    }
    .service-bg li a {
        padding: 5px 10px;
    }

    
}

@media only screen and (max-width: 414px) {
    .service-bg li {
        width: 100%;
    }
}

.over.d-block {
    display: block;
}

.over.d-none {
    display: none;
}