* {
    padding: 0;
    margin: 0;
    font-family: Poppins, Sans-serif;
}

header .inner {
    padding: 2px 0;
    border-bottom: 1px solid #fff;
}

.justify-content-space {
    justify-content: space-between;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

header ul.social_icons_wrapper {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

header ul.social_icons_wrapper a {
    color: #fff;
    font-size: 17px;
    padding: 0 16px;
}

header ul.icon_list_items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

header li.icon_list_item {
    display: flex;
    align-items: center;
}

header ul.icon_list_items a {
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 20px;
}

header ul.icon_list_items a i {
    font-size: 14px;
}

header ul.icon_list_items li.icon_list_item:after {
    content: "";
    border-right: 1px solid #ddd;
    display: inline-block;
    height: 15px;
    margin: 0 11px;
}

header .menue .raven_nav_menu {
    display: flex;
    list-style: none;
    gap: 8px;
}

.close-btn,
header ul.icon_list_items li.icon_list_item:last-child:after {
    display: none;
}

header .menue .raven_nav_menu a {
    text-decoration: none;
    padding: 10px 20px;
    border-bottom: 2px solid #0000;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}

.hero-slider img {
    max-height: 390px;
}

header .menue .raven_nav_menu a.active {
    border-color: #ffb947;
}

header .menue .raven_nav_menu a.button_link,
header .button_wrapper a.button_link {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    background-color: #5247ff;
    padding: 12px 15px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    text-decoration: none;
}

header ul.icon_list_items a svg {
    font-size: 14px;
}

header ul.social_icons_wrapper a svg {
    color: #fff;
    font-size: 15px;
}

.container.nav-main {
    transition: all 1s;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sticky {
    background-color: #252734;
    box-shadow: 0 2px 10px #0000001a;
    left: 0;
    padding: 0 100px;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 1s;
    width: 100%;
    z-index: 1000;
}

.container.nav-main.sticky {
    width: 100%;
}


@media only screen and (min-width: 1025px) {
    header .raven-nav-menu-toggle {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    header .menue .raven_nav_menu li:last-child {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {

    .container,
    header .icon_list_items_header {
        width: 100%
    }

    .container {
        padding: 0 10px
    }

    header .inner-section {
        flex-wrap: wrap
    }

    header ul.social_icons_wrapper {
        width: 100%;
        justify-content: center
    }

    header ul.icon_list_items {
        justify-content: center
    }

    header .image img {
        width: 100px;
        height: auto
    }

    header .menue {
        order: 0
    }

    header .image {
        order: 1
    }

    header .button_wrapper {
        order: 2
    }

    header .raven-nav-menu-toggle-button {
        display: inline-block;
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
        cursor: pointer;
        color: #fff
    }

    header .menue .raven_nav_menu {
        display: none
    }

    header .menue .raven_nav_menu.active {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        background: #252734;
        z-index: 999999999999;
        padding: 20px;
        width: 75%;
    }

    header .menue .raven_nav_menu.active .close-btn {
        display: inline-block;
        position: absolute;
        right: 0;
        font-size: 22px;
        color: #fff;
        top: 0;
        padding: 10px;
    }

    header .menue .raven_nav_menu a {
        display: block;
        font-size: 14px;
        border-bottom: 1px solid #494949;
        padding: 10px 10px;
    }

    header .button_wrapper a.button_link {
        padding: 10px 10px;
    }

    header .menue .raven_nav_menu a.button_link {
        display: inline-block;
        margin-top: 20px;
    }
}


@media only screen and (max-width: 767px) {

    header .button_wrapper,
    header .icon_list_items_header {
        display: none;
    }

    header .menue {
        order: 1;
    }

    header .image img {
        width: 80px;
    }
}