.FooterMain {
  border-top: 5px solid blue;
  background-color: #252734;
  color: white;
  padding: 70px 37px;
  margin-top: 100px;
}
.icon_list_icon {
  width: auto;
}
.container-foo {
  width: 90%;
  margin: 0 auto;
}
.flex-boxes {
  display: flex !important;
  justify-content: space-between;
  gap: 30px;
}

.content1 {
  width: 30%;
  padding-right: 20px;
}

.content1 h5{
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 0;
  position: relative;
  margin-bottom: 0;
}
.content3 h5{
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 0;
  position: relative;
  margin-bottom: 0;
}
.content6 h5{
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 0;
  position: relative;
  margin-bottom: 0;
}
.reed {
  color: #ffb947;
  text-decoration: underline;
}
.content1 h5::after {
  content: "";
  height: 2px;
  border-radius: 0.25rem;
  background-color: #ffb947;
  width: 29%;
  position: absolute;
  bottom: -5px;
  left: 0;
}
.content3 h5::after {
  content: "";
  height: 2px;
  border-radius: 0.25rem;
  background-color: #ffb947;
  width: 29%;
  position: absolute;
  bottom: -5px;
  left: 0;
}
.content6 h5::after {
  content: "";
  height: 2px;
  border-radius: 0.25rem;
  background-color: #ffb947;
  width: 29%;
  position: absolute;
  bottom: -5px;
  left: 0;
}
.content1 p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding-top: 20px;
  color: #ffff;
}

.content4 img {
  width: 90%;
  padding-top: 45px;
}
.list-section {
  padding-top: 23px;
}
.content1 ul li {
  display: flex;
  gap: 20px;
  padding-bottom: calc(10px / 2);
  align-items: center;
  margin-bottom: 5px;
}

.sp {
  color: #ffff;
}
.img-box {
  width: 250px;
  height: 300px;
  background-color: red;
  text-align: center;
  overflow-y: scroll;
}
.list-section li {
  display: flex;
  gap: 20px;
  padding-bottom: calc(21px / 2);
  align-items: center;
}
.list-section li span {
  font-size: 14px;
}
.list-section li span a {
  color: #fff;
}
.inner-top {
  position: fixed;
  background-color: #ffff;
}
.inimg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.copy {
  background: #252734;
  margin-top: 1px;
  text-align: center;
  padding: 13px 0;
  color: #fff;
  font-size: 14px;
}
.Copyright {
  text-align: center;
}
.copy span {
  color: #ffb947;
}
.inimg img {
  width: 100%;
  height: 100%;
}
.gmain {
  width: 90%;
  margin: auto;
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
  overflow: scroll;
  height: 200px;
}
.gmain::-webkit-scrollbar {
  display: none;
}
.gmain::-webkit-scrollbar-thumb {
  width: 2px;
  background: #ffb947;
}
.g1 {
  width: 93% !important;
  background-color: #fafafa;
  padding: 20px;
  flex: 0 0 auto;
  height: auto;
}
.typ {
  height: 116px;
  overflow-y: scroll;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}

.f-margin .slick-prev:before {
  content: "←" !important;

  border-radius: 100% !important;
  font-family: sans-serif !important;
}
.f-margin .slick-next:before {
  content: "→" !important;
  border-radius: 100% !important;
  font-family: sans-serif !important;
}
.f-margin .slick-prev:before,
.f-margin .slick-next:before {
  width: 40px !important;
  display: block !important;
  height: 40px !important;
  line-height: 37px !important;
  background: white !important;
  color: black !important;
  box-shadow: 2px 2px 12px #6b6b6b45 !important;
  opacity: 1 !important;
  font-size: 22px !important;
}
.f-margin .slick-next {
  right: 10px !important;
}
.clf {
  color: #154fc1;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.year {
  font-size: 13px;
  color: #555;
  margin: 0;
}
.h {
  width: 40px;
  height: 40px;
  background-color: #0077b6;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  color: white;
  margin-right: 10px;
}
.h-flex {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}
.st5 {
  color: #ffb947;
  margin-top: 6px;
  margin-bottom: 6px;
}
.cnt {
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
}
.f-margin {
  margin-bottom: 60px;
  margin-top: 45px;
}
.text-head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  margin-top:40px;
  margin-bottom: 40px;
  color: #000;
  font-weight: 600;
}
.orang-color {
  color: #ffb947;
}

.typ::-webkit-scrollbar {
  width: 3px;
}
.typ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.typ::-webkit-scrollbar-thumb {
  background: #504b4b;
}

.typ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: rgba(28, 28, 28, 0.9);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 40px;
}

@media only screen and (max-width: 600px) {
  .FooterMain {
    border-top: 5px solid blue;
    background-color: #252734;
    color: white;
    padding: 70px 0px;
    margin-top: 100px;
  }
  .text-head{
    font-size: 20px;
  }
  .content1 {
    width: 100% !important;
    padding-bottom: 16px;
  }
  .flex-boxes {
    display: block !important;
    justify-content: space-around;
    gap: 30px;
  }
  .cnt {
    text-align: center;
    margin-bottom: 30px;
    font-size: 10px !important;
  }
  .copy {
    background: #252734;
    margin-top: 1px;
    text-align: center;
    padding: 13px 0;
    color: #fff;
    font-size: 10px;
    padding: 10px 10px;
  }
  .Copyright {
    text-align: center;
    font-size: 10px;
  }
  .content4 {
    width: 83% !important;
    display: block;
    height: 310px;
    overflow: hidden;
    border: 2px solid #5247ff !important;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5) !important;
    height: 300px;
    margin: 0 auto;
  }
  .content6 {
    width: 100% !important;
    padding-bottom: 16px;
  }
  .content3 {
    width: 100% !important;
    padding-bottom: 16px;
  }
  .typ {
    height: 97px;
    overflow-y: scroll;
    font-size: 14px;
  }
  .f-margin, .slick-prev {
    left: -27px !important;
}
  .f-margin .slick-next {
    right: -5px !important;
}
.g1 {
  width: 100% !important;
  height: 200px;
  background-color: #fafafa;
  padding: 10px;
  flex: 0 0 auto;
  height: auto;
}
.contan{
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 30px !important;
}
}
.content4 {
  width: 25%;
  display: block;
  box-shadow: 0 4px 8px 0 #154fc1;
  height: 310px;
  border: 1px solid #fff;
  overflow: hidden;
}
.content3 {
  width: 28%;
}
.content6 {
  width: 20%;
}
.f-margin,
.slick-prev {
  left: -22px !important;
}

















.copyright_section,.footer_inner_main,.lets_work_header,header {
  background: #252734
}
.widget_container {
  padding-top: 20px
}
.footer_inner_main {
  border-top: 5px solid #5247ff
}

.footer_inner_main .footer_inner_items {
  color: #fff;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding: 80px 0 60px
}

.footer_inner_main li.icon_list_item {
  display: flex;
  gap: 20px;
  padding-bottom: calc(10px / 2);
  align-items: center;
  justify-content: start;
}

.footer_inner_main .footer_inner_item h5 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 0;
  position: relative;
  margin-bottom: 0
}

.footer_inner_main .widget_container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px
}

.footer_inner_main span.icon_list_text {
  font-size: 15px
}

.footer_inner_main iframe {
  border: 2px solid #5247ff;
  box-shadow: 0 0 10px 0 rgba(255,255,255,.5);
  height: 300px
}

.footer_inner_item h2:after,.footer_inner_main .footer_inner_item h5:after {
  border-radius: .25rem;
  background-color: #ffb947;
  content: '';
  margin-right: 2%;
  display: block
}

.footer_inner_main .item_one {
  width: 30%;
  padding-right: 30px
}

.footer_inner_main .item_two {
  width: 28%
}

.footer_inner_main .footer_inner_item h5:after {
  height: 2px;
  width: 29%;
  position: absolute;
  bottom: -5px;
  left: 0
}

.widget_container span a {
  text-decoration: none
}

.footer_inner_main .item_three {
  width: 20%
}

.footer_inner_main .item_four {
  width: 25%
}

.copyright_section {
  border-top: 1px solid #fff;
  padding: 10px
}

.copyright_section p {
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin: 0
}

.copyright_section p a {
  color: #ffb947;
  text-decoration: none
}


.footer_inner_item h2:after {
  width: 24%;
  height: 3px;
  margin-top: 5px
}

span.icon_list_icon svg {
  width: 12px;
  height: auto;
  fill: #fff
}
.footer_inner_main li.icon_list_item a {
  color: #fff;
  line-height: 30px;
}

.footer_inner_items .widget_container p a {
  color: #ffb947;
  text-decoration: underline
}
@media only screen and (max-width: 1024px) {
  .footer_inner_main .footer_inner_items {
      flex-wrap: wrap;
      justify-content: center
  }

  .footer_inner_main .item_one {
      width: 100%;
      padding: 0 30px 0 0
  }

  .footer_inner_main .item_three,.footer_inner_main .item_two {
      width: 47%
  }

  .footer_inner_main .item_four {
      width: 100%;
      margin: auto;
      text-align: center
  }

  .footer_inner_main iframe {
      width: 300px;
      margin: auto
  }
}
@media only screen and (max-width: 767px) {
  .footer_inner_items {
      flex-direction: column
  }

  .footer_inner_main .item_four,.footer_inner_main .item_one,.footer_inner_main .item_three,.footer_inner_main .item_two {
      width: 100%
  }

  .footer_inner_item h2:after {
      width: 10%
  }

  .footer_inner_main .widget_container {
      padding-top: 20px
  }
}
@media only screen and (max-width: 414px) {
  .footer_inner_item h2:after {
      width:23%;
      top: 14%
  }
  section.section-reviews {
    padding: 10px 10px 60px;
  }
}