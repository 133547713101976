.cms-banner {
    background-image: url(https://www.fahimshakir.com/core/wp-content/uploads/2024/03/software.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 50px 10px;
}
.breadcrumb-item a::after {
    content: "/";
    color: #fff;
    padding-left: 8px;
    font-size: 12px;
}
.cms-banner-breadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
}
h1.cms-banner-title {
    color: #fff;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
}
.breadcrumb-item a {
    color: #ffb947;
    text-decoration: none;
}
.breadcrumb-item.active span {
    color: #fff;
}
.breadcrumb-item span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
@media only screen and (max-width: 768px) {
    h2.cms-banner-title {
        font-size: 28px;
        margin-bottom: 10px;
    }
    .cms-banner {
        height: 200px;
        padding-left: 0;
        padding: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .cms-banner {
        height: 150px;
    }
}

.blog-details {
    padding: 60px 0;
}
.blog-details-post {
    width: 70%;
}
.blog-sidebar {
    width: 30%;
}
.blog-image a img {
    width: 100%;
}
.bd-row {
    display: flex;
    gap: 40px;
}

.blog-image {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.blog-image a img {
    border-radius: 12px;
    transition: all 0.3s linear;
}

.blog-image a img:hover {
    transform: scale(1.1);
}

.top-meta {
    position: absolute;
    top: 35px;
    left: 30px;
}

.top-meta span.date {
    width: 65px;
    height: 65px;
    color: #fff;
    font-size: 14px;
    height: fit-content;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    line-height: 12px;
    padding: 10px;
    font-family: "Barlow", sans-serif;
    background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
}

.top-meta span.date span {
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    display: block;
}

.blog-content {
    padding: 30px 0px 0px;
}

.blog-meta span.tag {
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    color: #086ad8;
}

span.author a:hover {
    color: #086ad8;
}

.blog-meta {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.blog-meta span {
    display: flex;
    align-items: center;
    gap: 8px;
}

.blog-meta span a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #6c757d;
    text-decoration: none;
    text-transform: capitalize;
}

.blog-meta p.comment {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    color: #999999;
    margin-right: 15px;
}

.blog-content h2.title {
    font-size: 28px;
    line-height: 46px;
    font-weight: 600;
    color: #0e0e0e;
    padding-right: 15px;
    margin-top: 15px;
    font-family: "Barlow", sans-serif;
}

.blog-content p.text {
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
    font-weight: 400;
    font-family: "Jost", sans-serif;
    color: #4c4d56;
}

.blog-details-content .blog-quote {
    position: relative;
    margin-left: 50px;
    margin-top: 40px;
}

.blog-details-content blockquote.blockquote {
    padding-right: 125px;
    padding-left: 25px;
    margin-bottom: 0;
    position: relative;
}

.blog-details-content blockquote.blockquote p {
    display: inline;
    font-size: 18px;
    font-family: "Barlow", sans-serif;
    color: #333333;
    line-height: 32px;
    font-weight: 600;
}

.blog-details-content .blog-quote:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #086ad8;
    width: 6px;
    height: 100%;
}

.blog-details-content .blog-details-text {
    margin-top: 50px;
}

.blog-details-text p {
    font-size: 16px;
    line-height: 30px;
}

.blog-details-tag-share {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 65px;
    padding-top: 20px;
    border-top: 1px solid #e3e3e5;
}

.blog-details-tag-share .sidebar-widget {
    display: flex;
    align-items: flex-start;
}

.blog-details-tag-share span.label {
    font-size: 14px;
    line-height: 30px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    color: #333333;
    margin-right: 10px;
}

.blog-details-tag-share ul.sidebar-tag {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog-details-tag-share ul.sidebar-tag li,
.blog-details-tag-share ul.social-share li {
    list-style: none;
}

.blog-details-tag-share ul.sidebar-tag li a {
    display: inline-block;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 14px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    background: #f9f9f9;
    transition: all 0.3s linear;
    text-decoration: none;
}

.blog-details-tag-share ul.sidebar-tag li a:hover {
    background-color: #086ad8;
    color: #ffffff;
}

.blog-details-tag-share ul.social-share {
    display: flex;
    align-items: center;
    gap: 12px;
}

.blog-details-tag-share ul.social-share li a {
    width: 40px;
    height: 40px;
    border: 1px solid #ededed;
    color: #086ad8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-details-tag-share ul.social-share li a svg {
    width: 20px;
}

.techwix-post-pagination {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.16);
    padding: 10px 15px;
    overflow: hidden;
    position: relative;
}

.techwix-post-pagination .previous-post,
.techwix-post-pagination .next-post {
    width: 50%;
}

.techwix-post-pagination .blog-pagination-post {
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.techwix-post-pagination .post-thumb a {
    display: flex;
    align-items: center;
}

.techwix-post-pagination span.flaticon-back svg {
    font-size: 14px;
    color: #086ad8;
    padding: 0 10px;
}

.blog-pagination-post .post-content {
    flex-grow: 1;
    padding: 0 20px;
}

.blog-pagination-post h4.title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: #0e0e0e;
    height: 40px;
    overflow: hidden;
}

.blog-pagination-post h4.title a {
    text-decoration: none;
    color: #0e0e0e;
}

.blog-pagination-post span.date {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    line-height: 22px;
    color: #838383;
}

.blog-pagination-post span.date svg {
    width: 14px;
    /*fill: #086ad8;*/
}

.techwix-post-pagination .post-thumb img {
    border-radius: 10px;
    object-fit: contain;
}

.text-end {
    text-align: right !important;
}

.next-post .blog-pagination-post span.date {
    flex-direction: row-reverse;
}

.techwix-post-pagination:before {
    position: absolute;
    content: '';
    width: 1px;
    background-color: #e1e1e1;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    bottom: 30px;
}

.next-post span.flaticon-next svg {
    font-size: 14px;
    color: #086ad8;
    padding: 0 10px;
}

.comment-form {
    margin-top: 50px;
}

.comment-form h3.comment-title {
    font-size: 22px;
    line-height: 30px;
    color: #0e0e0e;
}

.comment-form p {
    font-size: 14px;
    line-height: 26px;
    color: #686f7a;
}

.comment-form .form-control {
    border: 1px solid transparent;
    background: #f6f7f9;
    border-radius: 0;
}

.comment-form .single-form {
    width: 100%;
    overflow: hidden;
}

.cf-row {
    display: flex;
    gap: 20px;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]),
textarea {
    box-shadow: none;
    color: #415674;
    background: #f6f7f9;
    margin-bottom: 0;
    padding: 10px 25px;
    max-width: 100%;
    width: 100%;
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    transition: all 0.3s linear;
}

.single-form input,
textarea {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 0;
}

.single-form input:focus,
.single-form textarea:focus {
    border-color: #086ad8;
}

.single-form textarea {
    height: 135px;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 25px;
    resize: none;
}

.comment-form-wrap form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comment-form-wrap {
    margin-top: 30px;
}

.form-btn button.btn {
    height: 40px;
    line-height: 38px;
    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    font-size: 16px;
    padding: 0 40px;
}

.form-btn .btn {
    background: linear-gradient(195deg, #086ad8 0%, #42b9ff 100%);
    color: #ffffff;
    border-radius: 5px;
    font-size: 18px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    border: 0;
    transition: all 0.5s ease 0s;
    cursor: pointer;
}


.blog-sidebar form.search-form {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.blog-sidebar form.search-form input {
    width: 100%;
    border: 0;
    padding-right: 35px;
    background: #f2f4f7;
    color: #415674;
    font-weight: 700;
    outline: none;
    overflow: hidden;
}

form.search-form button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    color: #111111;
    transition: all 0.3s linear;
}

.blog-sidebar {
    position: sticky;
    top: 0;
    padding: 20px;
    height: fit-content;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.blog-sidebar .sidebar-widget-1 {
    margin-top: 0;
}

.widget-title h3.title {
    font-size: 20px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    color: #0e0e0e;
    display: inline-block;
    margin: 0;
    line-height: normal;
}

.sidebar-widget .widget-title {
    margin-bottom: 25px;
}

.sidebar-widget.other-posts {
    margin-top: 40px;
}

.sidebar-widget.tags {
    margin-top: 40px;
}

.recent-posts a.post-link {
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
}

.recent-posts .post-thumb img {
    width: 60px;
    height: 60px;
    border-radius: 53px;
}

.recent-posts ul li {
    list-style: none;
}

.recent-posts ul li:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
}

.recent-posts ul li {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    margin-top: 20px;
}

.recent-posts h4.title {
    color: #0e0e0e;
    font-size: 16px;
    line-height: 18px;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    transition: all 0.3s linear;
}

.recent-posts .post-meta {
    font-size: 12px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    line-height: 24px;
    color: #838383;
    display: inline-block;
    margin-top: 5px;
}

.recent-posts .post-meta {
    display: flex;
    align-items: center;
    gap: 6px;
}

.blog-sidebar .widget-banner {
    /* background-image: url(assets/images/sidebar-img.jpg); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    padding: 60px 40px 90px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.blog-sidebar .banner-content h4.title {
    font-size: 36px;
    line-height: 1;
    font-weight: 600;
    color: #ffffff;
}

.banner-content a.btn {
    display: inline-block;
    overflow: hidden;
    transform: perspective(0) translateZ(0);
    background: linear-gradient(195deg, #086ad8 0%, #42b9ff 100%);
    color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    line-height: 45px;
    transition: all 0.5s ease 0s;
    cursor: pointer;
    position: relative;
    width: 100%;
    text-align: center;
    text-decoration: none;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-top: 30px;
}

.banner-content a.btn:hover {
    background: #000;
}

.sidebar-widget ul.category li {
    list-style: none;
}

.sidebar-widget .cate-item a {
    text-decoration: none;
}

.category .cate-item a {
    display: flex;
    font-size: 16px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    color: #29303c;
    transition: all 0.3s linear;
    align-items: center;
}

details.category .cate-item a {
    font-size: 15px;
}

.sidebar-widget .category-list li {
    font-size: 18px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ebebeb;
    padding-top: 5px;
    margin-top: 5px;
}
.sidebar-widget .category-list li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #000;
}
.sidebar-widget u.category-listl li p {
    margin: 0;
    font-size: 16px;
}
.sidebar-widget .category-list li:hover span.post-count {
    color: #fff;
    background-color: #086ad8;
}

.sidebar-widget .category .cate-item {
    border-top: 1px solid #ebebeb;
    padding-top: 10px;
    margin-top: 15px;
}

.sidebar-widget .category .cate-item:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
}

.blog-sidebar {
    box-sizing: border-box;
}

span.post-count {
    margin-left: auto;
    align-items: flex-start;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #e4f2f8;
    font-size: 14px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    color: #086ad8;
    transition: all 0.3s linear;
}

.sidebar-widget .category .cate-item a:hover {
    color: #086ad8;
}

.sidebar-widget .category .cate-item a:hover span.post-count {
    background: #086ad8;
    color: #fff;
}

ul.sidebar-tag li {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 4px;
    list-style: none;
}

.sidebar-widget .sidebar-tag li a {
    display: inline-block;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 14px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    background: #f9f9f9;
    transition: all 0.3s linear;
    text-decoration: none;
}

.sidebar-widget .sidebar-tag li a:hover {
    background-color: #086ad8;
    color: #ffffff;
}

.cms-blog-details .title {
    margin-bottom: 0px;
}
.paradiv, .second-div, p {
    margin: 0;
}
.cms-blog-details .recent-posts .post-meta svg,
.cms-blog-details .techwix-post-pagination span.date svg {
    color: #5247ff;
}
.cms-blog-details ul.category-list li.category a p {
    font-size: 16px;
}
.sidebar-widget .category-list li:first-child {
    border-top: 0;
}
.cms-blog-details .techwix-post-pagination .post-content span.date {
    margin-top: 10px;
}
.cms-blog-details .techwix-post-pagination .post-content h4.title a {
    transition: 0.3s;
}
.cms-blog-details .techwix-post-pagination .post-content h4.title a:hover {
    color: #5247ff;
}
.cms-blog-details .sidebar-widget .widget-title {
    margin-bottom: 20px;
}
.cms-blog-details .blog-details-tag-share {
    padding-top: 20px;
}
.cms-blog-details span.post-count {
    color: #5247ff;
    background: #5247ff21;
}
.cms-blog-details .sidebar-widget .category-list li:hover span.post-count {
    background-color: #5247ff;
}
.cms-blog-details .recent-posts a.post-link:hover h4.title {
    color: #5247ff;
}
.cms-blog-details .blog-content h2.title {
    margin-top: 10px;
    margin-bottom: 15px;
}
.cms-blog-details .top-meta span.date {
    background: linear-gradient(-180deg, #5247ff, #7141b1) !important;
}
.cms-blog-details .blog-meta span.tag {
    color: #5247ff;
}
@media only screen and (max-width: 1024px) {
    .blog-details {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 991px) {
    .blog-details {
        max-width: 720px;
        margin: auto;
    }

    .bd-row {
        flex-direction: column;
    }

    .blog-details-post,
    .blog-sidebar {
        width: 100%;
    }

    .blog-details-tag-share {
        padding-top: 40px;
    }

    .sidebar-widget {
        margin-top: 0;
    }

    .blog-details-tag-share .sidebar-widget {
        align-items: flex-start;
    }
    .cms-banner{
        padding: 40px 10px;
    }
    h1.cms-banner-title {
        font-size: 26px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 768px) {
    .blog-content h3.title {
        font-size: 28px;
        line-height: 34px;
    }

    .blog-sidebar {
        margin-left: 0px;
    }

    .sidebar-widget .widget-title {
        margin-top: 45px;
    } 
    .blog-content h2.title {
        font-size: 22px;
        line-height: 24px;

    }
    .blog-meta span {
        font-size: 14px;
    }
    .widget-banner {
        margin-top: 40px;
    }
    .cms-blog-details .sidebar-widget .widget-title {
        margin-top: 0;
    }
    .blog-image a img {
        height: auto;
    }
    h1.cms-banner-title {
        font-size: 22px;
        margin-top: 5px;
        margin-bottom: 0;
    }
    .breadcrumb-item span{
        font-size: 14px;
    }

    .cms-banner-breadcrumb .breadcrumb-item:nth-child(2) a:after,
    .cms-banner-breadcrumb .breadcrumb-item.active {
        display: none;
    }
}

@media only screen and (max-width: 614px) {
    .techwix-post-pagination {
        flex-direction: column;
    }

    .techwix-post-pagination .previous-post,
    .techwix-post-pagination .next-post {
        width: 100%;
    }

    .techwix-post-pagination:before {
        width: auto;
        height: 1px;
        left: 30px;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
    }

    .techwix-post-pagination {
        padding: 10px 10px;
    }

    .techwix-post-pagination .blog-pagination-post {
        padding: 20px 0;
    }
}

@media only screen and (max-width: 414px) {
    .blog-details {
        padding: 40px 0;
    }

    .blog-meta {
        gap: 10px;
    }

    .blog-content h3.title {
        font-size: 24px;
        line-height: 32px;
    }

    .blog-details-content blockquote.blockquote {
        padding-right: 0;
    }

    .blog-details-content .blog-details-text {
        margin-top: 30px;
    }

    .blog-details-tag-share {
        margin-top: 30px;
    }

    .blog-details-tag-share {
        padding-top: 30px;
    }

    .blog-details-content .blog-quote {
        margin-left: 40px;
        margin-top: 40px;
    }

    .cf-row {
        flex-direction: column;
    }

    .blog-sidebar .banner-content h4.title {
        font-size: 28px;
    }

    .sidebar-widget .widget-title {
        margin-top: 0;
    }

    .widget-banner {
        margin-top: 30px;
    }

    .blog-sidebar {
        padding: 15px;
    }

    .recent-posts ul li {
        padding-top: 25px;
        margin-top: 25px;
    }

    .blog-content {
        padding: 20px 0px 0px;
    }
    h1.cms-banner-title {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 5px;
    }
    .breadcrumb-item span {
        font-size: 14px;
    }
    .banner.container {
        padding: 0;
    }
}